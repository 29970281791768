import axios from "axios";

// https://ylight-music-api.vercel.app

// http://localhost:3000/play?id=uIdx82qJ9X4

// https://dc-music-api.herokuapp.com

export default axios.create({
  baseURL: "https://dc-music-api.herokuapp.com",
  // baseURL: 'https://ylight.glitch.me',
});
